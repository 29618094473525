<template>
  <div>
    <div id="header">
      <table style="width: 100%; table-layout: auto">
        <tr>
          <td style="text-align: left; width: 50%">
            <h4 style="font-weight: bold">
              {{
                lang === "de"
                  ? "Liste der aktuellen Mitarbeiter"
                  : "List of current employees"
              }}
            </h4>
          </td>
          <td style="text-align: right; width: 50%">
            <div>{{ currentCompany.name }}</div>
            <div v-if="currentSettings.master_data.address">
              {{ currentSettings.master_data.address }}
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="first-page">
      <hr
        style="border-top: 1px solid #333; margin-top: 40px; margin-bottom: 5px"
      />
      <table style="width: 100%; justify-content: center">
        <tr>
          <td style="width: 30%">
            <div style="font-size: 13px">Name</div>
          </td>
          <td style="width: 30%">
            <div v-if="lang === 'de'" style="font-size: 13px">
              Addresse
              <br />
              Email/Tel.Nr.
            </div>
            <div v-else>
              Address
              <br />
              Email/Phone
            </div>
          </td>
          <td style="width: 25%">
            <div v-if="lang === 'de'" style="font-size: 13px">
              Beruf
              <br />
              Geb.Dat.
            </div>
            <div v-else>
              Profession
              <br />
              Birthday
            </div>
          </td>
          <td style="width: 15%">
            <div style="font-size: 13px">
              {{ lang === "de" ? "Stellenantritt" : "Employment" }}
            </div>
          </td>
        </tr>
      </table>

      <hr style="border-top: 1px solid #333; margin-top: 5px" />
      <table
        style="
          width: 100%;
          margin-top: 10px;
          justify-content: center;
          border-collapse: collapse;
          page-break-inside: auto;
        "
      >
        <div
          v-for="(item, i) in currentUserReport"
          :key="i"
          style="display: contents"
        >
          <tr>
            <td style="width: 30%">
              <div
                style="
                  font-size: 13px;
                  font-weight: bold;
                  page-break-inside: avoid;
                  margin: 4px 0 4px 0;
                "
              >
                {{ item.first_name + " " + item.last_name }}
              </div>
            </td>
            <td style="width: 30%">
              <div
                style="
                  font-size: 13px;
                  page-break-inside: avoid;
                  margin: 4px 0 4px 0;
                "
              >
                {{ item.address + " - " + item.place }}<br />{{ item.email
                }}<br />{{ item.phone }}
              </div>
            </td>
            <td style="width: 25%">
              <div
                style="
                  font-size: 13px;
                  page-break-inside: avoid;
                  margin: 4px 0 4px 0;
                "
              >
                {{ item.job }}<br />{{ formatDate(item.dob) }}
              </div>
            </td>
            <td style="width: 15%">
              <div
                style="
                  font-size: 13px;
                  page-break-inside: avoid;
                  margin: 4px 0 4px 0;
                "
              >
                {{ formatDate(item.start_work_date) }}
              </div>
            </td>
          </tr>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { GET_COMPANY, GET_SETTINGS } from "@/core/services/store/settings";
import { GET_USER_REPORT } from "@/core/services/store/report";

export default {
  name: "exportListUser",
  data() {
    return {
      today: ""
    };
  },
  computed: {
    ...mapGetters(["currentCompany", "currentSettings", "currentUserReport"]),
    lang() {
      if (this.$route.query.lang) {
        return this.$route.query.lang;
      } else {
        return "de";
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(GET_COMPANY);
    await this.$store.dispatch(GET_SETTINGS);
    await this.$store.dispatch(GET_USER_REPORT);
    this.today = moment().format("DD.MM.YYYY");
    this.initFont();
  },
  methods: {
    initFont() {
      let style = document.createElement("style");
      let font = this.currentSettings.pdf_layout.pdf_font;
      if (font === "arial") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Arial,Helvetica Neue,Helvetica,sans-serif;}"
          )
        );
      } else if (font === "open sans") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: 'Open Sans', sans-serif;}"
          )
        );
      } else if (font === "courier new") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;}"
          )
        );
      } else if (font === "georgia") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Georgia,Times,Times New Roman,serif;}"
          )
        );
      } else if (font === "verdana") {
        style.appendChild(
          document.createTextNode(
            "body { font-family: Verdana,Geneva,sans-serif;}"
          )
        );
      } else if (font === "klavika") {
        style.appendChild(
          document.createTextNode("body {font-family: 'Klavika';}")
        );
        style.appendChild(
          document.createTextNode(
            "@font-face {font-family: 'Klavika';src: url('/fonts/klavika-rg/Klavika-Regular.otf') format('truetype');font-weight: normal;font-style: normal;}"
          )
        );
        style.appendChild(
          document.createTextNode(
            "@font-face {font-family: 'Klavika';src: url('/fonts/klavika-rg/Klavika-Bold.otf') format('truetype');font-weight: bold;font-style: normal;}"
          )
        );
      }
      document.head.appendChild(style);
    },
    formatDate(val) {
      if (val) {
        return moment(val).format("DD.MM.YYYY");
      }
    }
  }
};
</script>

<style scoped>
.first-page {
  padding-left: 85px;
  padding-right: 85px;
  margin-bottom: -10px;
}

.first-page #content {
  width: 100%;
}

.first-page #content #title {
  margin-bottom: 100px;
}

.first-page #content #info {
  text-align: left;
  margin-bottom: 100px;
}

.first-page #footer {
  margin-top: 400px;
}

.first-page #footer #footer-content {
  justify-content: center;
  width: 100%;
}

h1 {
  font-size: 45px;
  margin-bottom: 0px;
}

h3 {
  font-size: 23px;
  margin-top: 0px;
}

#header {
  margin-left: 85px;
  margin-right: 85px;
  margin-bottom: 10px;
}

#footer {
  margin-left: 85px;
  margin-right: 85px;
  margin-top: 20px;
}
</style>

<style>
body {
  color: black;
  line-height: 1.1;
  background-color: #fff;
  font-size: 14px !important;
}

p {
  margin-bottom: 0;
}

i {
  color: #000 !important;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
